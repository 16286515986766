import db from '@/db'
import firebase from '@/firebase'

import {
    PUSH_TOXICITY_SCORE,
    RESET_TOXICITY_SCORES,
    SET_STATEMENT,
    RESET_STATEMENT,
    SET_MTURK_STATEMENT,
    RESET_MTURK_STATEMENT,
  } from './mutations'
  
  const state = {
    toxicityScores: [],
    newStatement: {
      dateCreated: null,
      dateUpdated: null,
      momentId: null,
      minderId: null,
      statementFeeling: null,
      statementOriginalText: null,
      statementStimulusText: null,
      statementType: null,
    },

    newMturkStatement: {
      dateCreated: null,
      dateUpdated: null,
      momentId: null,
      minderId: null,
      statementFeeling: null,
      statementOriginalText: null,
      statementStimulusText: null,
      statementType: null,
      intentMeanStatementText: null,
      intentMeanStatementId: null,
      endStatementText: null,
      endStatementId: null,
      valueStatementText: null,
      valueStatementId: null,
      actionMeanStatementText: null,
      actionMeanStatementId: null,
    },



    collectionName: 'v1_2020_statements_test'
    
  }
  
  const getters = {
    toxicityScores: ({toxicityScores}) => toxicityScores,
    newMturkStatement: ({newMturkStatement}) => newMturkStatement,
    newStatement: ({newStatement}) => newStatement,

    
  }
  
  const mutations = {
    [PUSH_TOXICITY_SCORE] (state, score) {
        state.toxicityScores.push(score)
      
    },
    [RESET_TOXICITY_SCORES] (state) {
        state.toxicityScores = []
    },
    [SET_STATEMENT] (state, payload) {
      state.newStatement.dateCreated = payload.dateCreated
      state.newStatement.dateUpdated = payload.dateUpdated
      state.newStatement.momentId = payload.momentId
      state.newStatement.minderId = payload.minderId
      if(payload.statementFeeling) {          //Only Checkin activities so far associated a feeling value to a statement.
        state.newStatement.statementFeeling = payload.statementFeeling
      }
      state.newStatement.statementOriginalText = payload.statementOriginalText
      state.newStatement.statementStimulusText = payload.statementStimulusText
      state.newStatement.statementType = payload.statementType
    },
    [RESET_STATEMENT] (state) {
      state.newStatement.dateCreated = null
      state.newStatement.dateUpdated = null
      state.newStatement.momentId = null
      state.newStatement.minderId = null
      state.newStatement.statementFeeling = null
      state.newStatement.statementOriginalText = null
      state.newStatement.statementStimulusText = null
      state.newStatement.statementType = null
    },
    [SET_MTURK_STATEMENT] (state, payload) {
      state.newMturkStatement.dateCreated = payload.dateCreated
      state.newMturkStatement.dateUpdated = payload.dateUpdated
      state.newMturkStatement.momentId = payload.momentId
      state.newMturkStatement.minderId = payload.minderId
      if(payload.statementFeeling) {          //Only Checkin activities so far associated a feeling value to a statement.
        state.newMturkStatement.statementFeeling = payload.statementFeeling
      }
      state.newMturkStatement.statementOriginalText = payload.statementOriginalText
      state.newMturkStatement.statementStimulusText = payload.statementStimulusText
      state.newMturkStatement.statementType = payload.statementType

      if(payload.intentMeanStatementText) {
        state.newMturkStatement.intentMeanStatementText = payload.intentMeanStatementText
        state.newMturkStatement.intentMeanStatementId = payload.intentMeanStatementId
        state.newMturkStatement.endStatementText = payload.endStatementText
        state.newMturkStatement.endStatementId = payload.endStatementId
        state.newMturkStatement.valueStatementText = payload.valueStatementText
        state.newMturkStatement.valueStatementId = payload.valueStatementId
        state.newMturkStatement.actionMeanStatementText = payload.actionMeanStatementText
        state.newMturkStatement.actionMeanStatementId = payload.actionMeanStatementId
      }
      
    },
    [RESET_MTURK_STATEMENT] (state) {
      state.newMturkStatement.dateCreated = null
      state.newMturkStatement.dateUpdated = null
      state.newMturkStatement.momentId = null
      state.newMturkStatement.minderId = null
      state.newMturkStatement.statementFeeling = null
      state.newMturkStatement.statementOriginalText = null
      state.newMturkStatement.statementStimulusText = null
      state.newMturkStatement.statementType = null

      state.newMturkStatement.intentMeanStatementText = null
      state.newMturkStatement.intentMeanStatementId = null
      state.newMturkStatement.endStatementText = null
      state.newMturkStatement.endStatementId = null
      state.newMturkStatement.valueStatementText = null
      state.newMturkStatement.valueStatementId = null
      state.newMturkStatement.actionMeanStatementText = null
      state.newMturkStatement.actionMeanStatementId = null
    },
  }
  
  const actions = {
    async createStatement ({commit, state}, payload) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {
            commit(RESET_STATEMENT)
            commit(SET_STATEMENT, payload)
            var statementRef = null
            //create statement ref
            if(payload.statementId) {
              statementRef = await db.collection(state.collectionName).doc(payload.statementId)
            }else {
              statementRef = await db.collection(state.collectionName).doc()
            }
            await statementRef.set({
              ...state.newStatement,
              'userUid': user.uid,
              'statementId': statementRef.id,
            })

            resolve(statementRef.id)
          } else {
            reject()
          }
        })
      })
    },
    async createMturkStatement ({commit, state}, payload) {
      return new Promise(function(resolve, reject) {
        firebase.auth().onAuthStateChanged(async (user) => {  
          if (user) {
            commit(RESET_MTURK_STATEMENT)
            commit(SET_MTURK_STATEMENT, payload)

            //create statement ref
            var mturkStatementRef = await db.collection('mturk_statements').doc()
            await mturkStatementRef.set({
              ...state.newMturkStatement,
              'userUid': user.uid,
              'statementId': mturkStatementRef.id,
            })

            resolve(mturkStatementRef.id)
          } else {
            reject()
          }
        })
      })
    },
    
    getToxicityScoresFromFS ({state}, minder) {

        //Get score data from four different objects, return as one package array 'scores'
        var scores = [];
        
        var imsPromise = db.collection(state.collectionName).doc(minder.intentMeanStatementId).get()
        var esPromise = db.collection(state.collectionName).doc(minder.endStatementId).get()
        var vsPromise = db.collection(state.collectionName).doc(minder.valueStatementId).get()
        var amsPromise = db.collection(state.collectionName).doc(minder.actionMeanStatementId).get()
        
        return Promise.all([
          imsPromise,
          esPromise,
          vsPromise,
          amsPromise

        ]).then((promises) => {
          var imsData = promises[0].data()
          //console.log("imsData: "+JSON.stringify(imsData))
          var esData = promises[1].data()
          //console.log("esData: "+ JSON.stringify(esData))
          var vsData = promises[2].data()
          //console.log("vsData: "+ JSON.stringify(vsData))
          var amsData = promises[3].data()
          //console.log("amsData: "+JSON.stringify(amsData))

          

          var imsScores = ('statementToxicityScores' in imsData) ? imsData.statementToxicityScores : null;
          var esScores = ('statementToxicityScores' in esData) ? esData.statementToxicityScores : null;
          var vsScores = ('statementToxicityScores' in vsData) ? vsData.statementToxicityScores : null;
          var amsScores = ('statementToxicityScores' in amsData) ? amsData.statementToxicityScores : null;

          if((imsScores != null) && (esScores != null) && (vsScores != null) && (amsScores != null)) {

            scores.push(imsData.statementToxicityScores)
            //commit(PUSH_TOXICITY_SCORE, msData.statementToxicityScores)
          
          
        
            scores.push(esData.statementToxicityScores)
            //commit(PUSH_TOXICITY_SCORE, esData.statementToxicityScores)
          
          
        
            scores.push(vsData.statementToxicityScores)
            //commit(PUSH_TOXICITY_SCORE, vsData.statementToxicityScores)
          

            scores.push(amsScores.statementToxicityScores)
          
            
            return Promise.resolve(scores)
          } else {
            return Promise.reject()
          }
        })
    },

  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }