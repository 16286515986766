//EXPERIENCE MUTATIONS

//Phase Managment
    //Phase & State Phase
    export const SET_XP_STATE = "SET_XP_STATE"
    export const SET_XP_PHASE = "SET_XP_PHASE"
    export const RESET_XP_STATE = "RESET_XP_STATE"
    export const RESET_XP_PHASE = "RESET_XP_PHASE"


//Session Management - 
    //Multiple xp sessions over a Minder experience
    export const SET_CURRENT_SESSION_ID = "SET_CURRENT_SESSION_ID"
    export const RESET_CURRENT_SESSION_ID = "RESET_CURRENT_SESSION_ID"


    //Xp Session Clickstream
    export const ADD_TO_XP_SESSION_CLICKSTREAM = "ADD_TO_XP_SESSION_CLICKSTREAM "
    export const RESET_XP_SESSION_CLICKSTREAM = "RESET_XP_SESSION_CLICKSTREAM"


//show Journal Dashboard after check-ins
    export const SET_SHOW_JOURNAL_DASHBOARD_VIEW = "SET_SHOW_JOURNAL_DASHBOARD_VIEW"


//Show Journal Setup view when setting up journal
    export const SET_SHOW_JOURNAL_SETUP_VIEW = "SET_SHOW_JOURNAL_SETUP_VIEW"