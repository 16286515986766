import db from '@/db'
import firebase from '@/firebase'

import {
  SET_NEW_MINDER_ID,
  REMOVE_MINDER,
  SET_MINDER,
  RESET_MINDER_LIST,
  PUSH_MINDER,
  PUSH_USER_MINDER_ID,
  PUSH_USER_MINDER,
  REMOVE_USER_MINDER,
  SWAP_USER_MINDER,
  RESET_USER_MINDERS,
  RESET_USER_MINDER_IDS,


  SET_INTENT_MEAN_STATEMENT,
  SET_END_STATEMENT,
  SET_VALUE_STATEMENT,
  SET_ACTION_MEAN_STATEMENT,


  SET_INTENT_MEAN_STATEMENT_STIMULUS_TEXT,
  SET_END_STATEMENT_STIMULUS_TEXT,
  SET_VALUE_STATEMENT_STIMULUS_TEXT,
  SET_ACTION_MEAN_STATEMENT_STIMULUS_TEXT,


  RESET_STATEMENTS,
  SET_LOADING,
  SET_GROUP_LOADING,
  SET_PROGRESS_BAR,



  REVERSE_USER_MINDERS
  } from './mutations'
  
  const state = {

    minderCollectionName: 'v1_2020_minders',
    statementCollectionName: 'v1_2020_statements',

    minder: null,
    list: [],
    userMinderIds: [],
    userMinders: [],

    

    intentMeanStatement: null,
    endStatement: null,
    valueStatement: null,
    actionMeanStatement: null,
    intentMeanStatementStimulusText: null,
    endStatementStimulusText: null,
    valueStatementStimulusText: null,
    actionMeanStatementStimulusText: null,
    nextStepStimulusText: null,
    newMinderId: null,
    newMinder: {
        intentMeanStatementText: null,
        endStatementText: null,
        valueStatementText: null,
        actionMeanStatementText: null
      },
    loading: [],
    groupLoading: [],
    showSpinner: false,

 
  }
  
  const getters = {
    newMinderId: ({newMinderId}) => newMinderId,
    list: ({list}) => list,
    userMinders: ({userMinders}) => userMinders,
    userMinderIds: ({userMinderIds}) => userMinderIds,
    minder: ({minder}) => minder,
    intentMeanStatement: ({intentMeanStatement}) => intentMeanStatement,
    endStatement: ({endStatement}) => endStatement,
    valueStatement: ({valueStatement}) => valueStatement,
    actionMeanStatement: ({actionMeanStatement}) => actionMeanStatement,
    loading: ({loading}) => loading,
    showSpinner: ({showSpinner}) => showSpinner,

  }
  
  const mutations = {
    


    [SET_NEW_MINDER_ID] (state, id) {
      state.newMinderId = id
    },

    [REMOVE_MINDER] (state, minderIndex) {
      if (state.list.length > 1) {
        state.list.splice(minderIndex, 1)
      }
    },
    
    [SET_MINDER] (state, minder) {
      state.minder = minder
    },
    [RESET_MINDER_LIST] (state) {
      state.list = []
    },
    [PUSH_MINDER] (state, minder) {
      state.list.push(minder)
    },


    [PUSH_USER_MINDER] (state, minder) {
        state.userMinders.push(minder)
    },
    [SWAP_USER_MINDER] (state, data) {
        if (state.userMinders.length >= 1) {
            //console.log("push user minder by splicing into position: "+data.minderIndex)
            //REPLACE
            state.userMinders.splice(data.minderIndex,1, data)
        }
    },
    [RESET_USER_MINDERS] (state) {
        state.userMinders = []
    },
    [REVERSE_USER_MINDERS] (state) {
        state.userMinders.reverse()
        //state.userMinderIds.reverse()
    },

    [PUSH_USER_MINDER_ID] (state, id) {
        state.userMinderIds.push(id)
    },
    [REMOVE_USER_MINDER] (state, minderIndex) {
        if (state.userMinderIds.length >= 1) {
            state.userMinderIds.splice(minderIndex, 1)
        }
        if (state.userMinders.length >= 1) {
            state.userMinders.splice(minderIndex, 1)
        }
    },
    [RESET_USER_MINDER_IDS] (state) {
        state.userMinderIds = []
    },
    
    


    [RESET_STATEMENTS] (state) {
      state.intentMeanStatement = null
      state.endStatement = null
      state.valueStatement = null
      state.actionMeanStatement = null
      state.newMinder.intentMeanStatementText = null
      state.newMinder.endStatementText = null
      state.newMinder.valueStatementText = null
      state.newMinder.actionMeanStatementText = null
    },


    [SET_INTENT_MEAN_STATEMENT] (state, statement) {
      state.intentMeanStatement = statement.statement
      state.newMinder.intentMeanStatementText = statement.statement
    },
    [SET_END_STATEMENT] (state, statement) {
      state.endStatement = statement.statement
      state.newMinder.endStatementText = statement.statement
    },
    [SET_VALUE_STATEMENT] (state, statement) {
      state.valueStatement = statement.statement
      state.newMinder.valueStatementText = statement.statement
    },
    [SET_ACTION_MEAN_STATEMENT] (state, statement) {
      state.actionMeanStatement = statement.statement
      state.newMinder.actionMeanStatementText = statement.statement
    },



    [SET_INTENT_MEAN_STATEMENT_STIMULUS_TEXT] (state, statementStimulusText) {
        state.intentMeanStatementStimulusText = statementStimulusText
    },
    [SET_END_STATEMENT_STIMULUS_TEXT] (state, statementStimulusText) {
        state.endStatementStimulusText = statementStimulusText
    },
    [SET_VALUE_STATEMENT_STIMULUS_TEXT] (state, statementStimulusText) {
        state.valueStatementStimulusText = statementStimulusText
    },
    [SET_ACTION_MEAN_STATEMENT_STIMULUS_TEXT] (state, statementStimulusText) {
        state.actionMeanStatementStimulusText = statementStimulusText
    },


    [SET_LOADING] (state, data) {
      state.loading[data.index] = data.bool
    },
    [SET_GROUP_LOADING] (state, data) {
      state.groupLoading[data.index] = data.bool
    },
    [SET_PROGRESS_BAR] (state, data) {
      state.showSpinner = data.bool
    },

  }
  
  const actions = {
    
    closeMinder ({commit}, data) {
        commit(REMOVE_MINDER, {minderIndex: data.index})
        db.collection(state.minderCollectionName).doc(data.minderId).set({
            'hasBeenModerated': true
        },{merge: true})
    },
    removeMinder ({commit, state}, data) {
        commit(REMOVE_MINDER, {minderIndex: data.index})
        db.collection(state.minderCollectionName).doc(data.minderId).delete()
        db.collection(state.statementCollectionName).doc(data.imsId).delete()
        db.collection(state.statementCollectionName).doc(data.esId).delete()
        db.collection(state.statementCollectionName).doc(data.vsId).delete()
        db.collection(state.statementCollectionName).doc(data.amsId).delete()
    },
    
    removeUserMinder ({commit, rootState}, data) {
        var minderIndex
            if(data.position === "one") {minderIndex = 0;}
            else if (data.position === "two") {minderIndex = 1;}
            else if (data.position === "three") {minderIndex = 2;}
            else if (data.position === "four") {minderIndex = 3;}
        commit(REMOVE_USER_MINDER, minderIndex)
        var userUid = rootState.auth.profile.uid
        db.collection('users').doc(userUid).set({
            "userMinderIds": firebase.firestore.FieldValue.arrayRemove(data.minderId)
        }, {merge: true})
    },
    async redoMinder ({commit,dispatch,state}, data) {
        commit(REMOVE_MINDER, {minderIndex: data.index})
        db.collection(state.minderCollectionName).doc(data.minderId).delete()
        //get the statement stimulus text
        //get the statement text
        await db.collection(state.statementCollectionName).doc(data.imsId).get().then(doc => {
            commit(SET_INTENT_MEAN_STATEMENT_STIMULUS_TEXT, doc.data().statementStimulusText)
            commit(SET_INTENT_MEAN_STATEMENT, {statement: doc.data().statementOriginalText})
            
        })
        db.collection(state.statementCollectionName).doc(data.imsId).delete()

        await db.collection(state.statementCollectionName).doc(data.esId).get().then(doc2 => {
            commit(SET_END_STATEMENT_STIMULUS_TEXT, doc2.data().statementStimulusText)
            commit(SET_END_STATEMENT, {statement: doc2.data().statementOriginalText})
        })
        db.collection(state.statementCollectionName).doc(data.esId).delete()


        await db.collection(state.statementCollectionName).doc(data.vsId).get().then(doc3 => {
            commit(SET_VALUE_STATEMENT_STIMULUS_TEXT, doc3.data().statementStimulusText)
            commit(SET_VALUE_STATEMENT, {statement: doc3.data().statementOriginalText})
        })
        db.collection(state.statementCollectionName).doc(data.vsId).delete()

        await db.collection(state.statementCollectionName).doc(data.amsId).get().then(doc4 => {
            commit(SET_ACTION_MEAN_STATEMENT_STIMULUS_TEXT, doc4.data().statementStimulusText)
            commit(SET_ACTION_MEAN_STATEMENT, {statement: doc4.data().statementOriginalText})
            
        })
        db.collection(state.statementCollectionName).doc(data.amsId).delete()


        dispatch('create')

    },
    async updateMinderStatuses ({state}, data) {
        await db.collection(state.minderCollectionName).doc(data.minderId).set({
            'intentMeanStatementStatus': data.imTaxStatus,
            'intentMeanStatementTaxonomyStatus': data.imTaxStatus,
            'endStatementStatus': data.eTaxStatus,
            'endStatementTaxonomyStatus': data.eTaxStatus,
            'valueStatementStatus': data.vTaxStatus,
            'valueStatementTaxonomyStatus': data.vTaxStatus,
            'actionMeanStatementStatus': data.amTaxStatus,
            'actionMeanStatementTaxonomyStatus': data.amTaxStatus,
            'taxonomyStatus': data.taxStatus,
            'nextStep': data.nextStep,
            //'hasBeenModerated': data.hasBeenModerated
        }, {merge: true})
        .then(function() {
            //console.log('minder status updated')
        }).catch(err => {
            /* eslint-disable no-console */
            console.err(err.message)
        })
    },


    async updateMinderStatements ({state}, data) {
        try {
            await db.collection(state.minderCollectionName).doc(data.minderId).set({
                'dateUpdated': data.dateUpdated,
                'intentMeanStatementStimulusText': data.intentMeanStatementStimulusText,
                'intentMeanStatementText': data.intentMeanStatementText,
                'intentMeanTaxonomy': data.intentMeanTaxonomy,
                'endStatementStimulusText': data.endStatementStimulusText,
                'endStatementText': data.endStatementText,
                'endTaxonomy': data.endTaxonomy,
                'valueStatementStimulusText': data.valueStatementStimulusText,
                'valueStatementText': data.valueStatementText,
                'valueTaxonomy': data.valueTaxonomy,
                'actionMeanStatementStimulusText': data.actionMeanStatementStimulusText,
                'actionMeanStatementText': data.actionMeanStatementText,
                'actionMeanTaxonomy': data.actionMeanTaxonomy,
            }, {merge: true})
            .then(function() {
                //console.log('minder statements updated')
            }).catch(err => {
                /* eslint-disable no-console */
                console.err(err.message)
            })
        } catch (e) {
            /* eslint-disable no-console */
            console.log("Errors: ", e)
        }
        
    },



    async createMinder ({dispatch,state}, payload) {
        //console.log("createMinder called")
        return new Promise(function(resolve, reject) {
            firebase.auth().onAuthStateChanged(async (user) => {  
                if (user) { 
                    var newDate = new Date()
                    //get new minder id, but don't create yet until I get mean, end, value statement IDs
                    var ref = await db.collection(state.minderCollectionName).doc()
                    
                    var aMomentId = (payload.momentId) ? payload.momentId : null
                    var aStatementId = (payload.statementId) ? payload.statementId : null
   
                    // create intent mean statement
                    var imsId = await dispatch('statement/createStatement', {
                        'dateCreated': newDate,
                        'dateUpdated': newDate,
                        'minderId': ref.id,
                        'momentId': aMomentId,
                        'statementOriginalText': payload.intentMeanStatementText,
                        'statementStimulusText': payload.intentMeanStatementStimulusText,
                        'statementType': 'intentMean',
                        'statementId': aStatementId,  //During migrations a statement is sometimes given a specific statement id, in this case we are creating brand new statements, so null
                    } , {root: true})


                    // create end statement
                    var esId = await dispatch('statement/createStatement', {
                        'dateCreated': newDate,
                        'dateUpdated': newDate,
                        'minderId': ref.id,
                        'momentId': aMomentId,
                        'statementOriginalText': payload.endStatementText,
                        'statementStimulusText': payload.endStatementStimulusText,
                        'statementType': 'end',
                        'statementId': aStatementId,  //During migrations a statement is sometimes given a specific statement id, in this case we are creating brand new statements, so null
                    } , {root: true})


                    // create value statement
                    var vsId = await dispatch('statement/createStatement', {
                        'dateCreated': newDate,
                        'dateUpdated': newDate,
                        'minderId': ref.id,
                        'momentId': aMomentId,
                        'statementOriginalText': payload.valueStatementText,
                        'statementStimulusText': payload.valueStatementStimulusText,
                        'statementType': 'value',
                        'statementId': aStatementId,  //During migrations a statement is sometimes given a specific statement id, in this case we are creating brand new statements, so null
                    } , {root: true})


                    // create intent mean statement
                    var amsId = await dispatch('statement/createStatement', {
                        'dateCreated': newDate,
                        'dateUpdated': newDate,
                        'minderId': ref.id,
                        'momentId': aMomentId,
                        'statementOriginalText': payload.actionMeanStatementText,
                        'statementStimulusText': payload.actionMeanStatementStimulusText,
                        'statementType': 'actionMean',
                        'statementId': aStatementId,  //During migrations a statement is sometimes given a specific statement id, in this case we are creating brand new statements, so null
                    } , {root: true})


                    // save new minder to database
                    await ref.set({
                        ...state.newMinder,
                        'minderId': ref.id,
                        'userUid': user.uid,
                        'nextStep': "create minder",
                        'intentMeanStatementId': imsId,
                        'intentMeanStatementText': payload.intentMeanStatementText,
                        'endStatementId': esId,
                        'endStatementText': payload.endStatementText,
                        'valueStatementId': vsId,
                        'valueStatementText': payload.valueStatementText,
                        'actionMeanStatementId': amsId,
                        'actionMeanStatementText': payload.actionMeanStatementText,
                        'dateCreated': new Date(),
                        'dateDeactivated': null,
                        'hasBeenModerated': false,
                        //'intentMeanTagList': [],
                        //'endTagList': [],
                        //'valueTagList': [],
                        //'actionMeanTagList': [],
                        'intentMeanStatementStatus': 'just created',
                        'endStatementStatus': 'just created',
                        'valueStatementStatus': 'just created',
                        'actionMeanStatementStatus': 'just created',
                        'intentMeanTaxonomy': {
                            'intentMeanSpecificityLevel': 'General',
                            'intentMeanTagList': [],
                            'intentMeanContextCategory': null,
                        },
                        'actionMeanTaxonomy': {
                            'actionMeanSpecificityLevel': 'General',
                            'actionMeanContextCategory': null,
                            'actionMeanTagList': [],
                        },
                        'endTaxonomy': {
                            'endTagList': [],
                            'endContextCategory': null,
                        },
                        'valueTaxonomy': {
                            'valueTagList': [],
                            'valueContextCategory': null
                        },
                        //'intentMeanContextCategory': null,      //initializing for later moderation/taxonomy application
                        //'endContextCategory': null,     //initializing for later moderation/taxonomy application
                        //'valueContextCategory': null,     //initializing for later moderation/taxonomy application
                        //'actionMeanContextCategory': null,     //initializing for later moderation/taxonomy application
                        'experienceState': ['toxicityUnchecked','minderJustCreated'],
                        'langCode': 'en'
                    })

                    //I should be dispatching this to the user module - DO LATER
                    //save minder to user if entered from Start page
                    var userRef =  db.collection('users').doc(user.uid)
                    await userRef.update({
                        'userMinderIds': firebase.firestore.FieldValue.arrayUnion(ref.id)                    
                    })
                    


                    resolve(ref.id)
                } else {
                    reject("No user logged in")
                }
            })
        })
    },


    

    //CREATE MINDERS HERE
    async createMturkMinder ({state, commit, dispatch}, obj) {
        return new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged(async (user) => {  
        
                if (user) {
                    //console.log("creating new mturk minder and 4 statements")
                    var newDate = new Date()
                    //get new minder id, but don't create yet until I get mean, end, value statement IDs
                    var ref = await db.collection('mturk_minders').doc()
                    commit(SET_NEW_MINDER_ID, ref.id)

                    var imsId = await dispatch('statement/createMturkStatement', {
                        'dateCreated': newDate,
                        'dateUpdated': newDate,
                        'minderId': ref.id,
                        'momentId': null,
                        'statementOriginalText': obj.answer1,
                        'statementStimulusText': state.intentMeanStatementStimulusText,
                        'statementType': 'intentMean',
                        
                    } , {root: true})


                    var esId = await dispatch('statement/createMturkStatement', {
                        'dateCreated': newDate,
                        'dateUpdated': newDate,
                        'minderId': ref.id,
                        'momentId': null,
                        'statementOriginalText': obj.answer2,
                        'statementStimulusText': state.endStatementStimulusText,
                        'statementType': 'end',
                        
                    } , {root: true})



                    var vsId = await dispatch('statement/createMturkStatement', {
                        'dateCreated': newDate,
                        'dateUpdated': newDate,
                        'minderId': ref.id,
                        'momentId': null,
                        'statementOriginalText': obj.answer3,
                        'statementStimulusText': state.valueStatementStimulusText,
                        'statementType': 'value',
                        
                    } , {root: true})



                    var amsId = await dispatch('statement/createMturkStatement', {
                        'dateCreated': newDate,
                        'dateUpdated': newDate,
                        'minderId': ref.id,
                        'momentId': null,
                        'statementOriginalText': obj.answer1,
                        'statementStimulusText': state.actionMeanStatementStimulusText,
                        'statementType': 'actionMean',
                    
                    } , {root: true})

                    // save new minder to database
                    await ref.set({
                        ...state.newMinder,
                        'minderId': ref.id,
                        'userUid': user.uid,
                        'nextStep': "create minder",
                        'intentMeanStatementId': imsId,
                        'intentMeanStatementText': obj.answer1,
                        'endStatementId': esId,
                        'endStatementText': obj.answer2,
                        'valueStatementId': vsId,
                        'valueStatementText': obj.answer3,
                        'actionMeanStatementId': amsId,
                        'actionMeanStatementText': obj.answer4,

                        'dateCreated': new Date(),
                        'dateDeactivated': null,
                        'hasBeenModerated': false,
                        'intentMeanTaxonomy': {
                            'intentMeanSpecificityLevel': 'General',
                            'intentMeanTagList': [],
                            'intentMeanContextCategory': null,
                        },
                        'actionMeanTaxonomy': {
                            'actionMeanSpecificityLevel': 'General',
                            'actionMeanContextCategory': null,
                            'actionMeanTagList': [],
                        },
                        'endTaxonomy': {
                            'endTagList': [],
                            'endContextCategory': null,
                        },
                        'valueTaxonomy': {
                            'valueTagList': [],
                            'valueContextCategory': null
                        },

                        'intentMeanStatementStatus': 'just created',
                        'endStatementStatus': 'just created',
                        'valueStatementStatus': 'just created',
                        'actionMeanStatementStatus': 'just created',

                        'experienceState': ['toxicityUnchecked','minderJustCreated'],
                        'langCode': 'en'
                    })


                    resolve(ref.id)
                } else {

                    reject()
                }
            })
        })
    },

    async get ({commit,state}, id) {
    const minder = await db.collection(state.minderCollectionName).doc(id).get()
    if (minder.exists) {
        commit(SET_MINDER, {
        id: minder.id,
        ...minder.data()
        })
    }
    },



    
    async getUserMinders({commit,state}) {
        return new Promise((resolve, reject) => {
            firebase.auth().onAuthStateChanged(async(user) =>{    //Spent many hours on a bug because user was not defined; when I know it was. Used onAuthStateChanged to make it work.
                //console.log("here's the user for getting user minders:",JSON.stringify(user))
                //unsubscribe()
                if (user) {
                    try {
                        //console.log("going to try to get minders")
                        
                        await db.collection(state.minderCollectionName).where("userUid", "==", user.uid).orderBy('dateCreated', 'desc')
                            .onSnapshot(function(querySnapshot){
                                //console.log("minder snapshot received")
                                //console.log('querySnapshot.size',querySnapshot.size)
                                commit(RESET_USER_MINDERS)
                                commit(RESET_USER_MINDER_IDS)
                                if(querySnapshot.size > 0) {
                                    querySnapshot.forEach(async (doc) => {

                                        //console.log("pushing user minder")
                                        commit(PUSH_USER_MINDER, {
                                            ...doc.data()
                                        })
                                        //console.log("pushing user minder id")
                                        commit(PUSH_USER_MINDER_ID, doc.data().minderId)

                                    })
                                }
                            })
                        
                    }catch(e) {
                        /* eslint-disable no-console */
                        console.log("Error: ", e)
                    }
                    resolve()
                } else {
                    reject("no user to get minders from")
                }
            })
        })
    },
    


    async getMinders ({dispatch, commit, state}) {
        commit(SET_PROGRESS_BAR, true)
        //const user = firebase.auth().currentUser
        //await db.collection('minders').where("userUid", "==", user.uid).where("hasBeenModerated", "==", false).orderBy('dateCreated', 'desc').onSnapshot(snap => {
        await db.collection(state.minderCollectionName).where("hasBeenModerated", "==", false).orderBy('dateCreated', 'desc').limit(1).onSnapshot(snap => {
        //db.collection('minders').where("userUid", "==", user.uid).orderBy('dateCreated', 'desc').get().then((snap) => {
            commit(RESET_MINDER_LIST)
            //var i = 0
            snap.forEach(async (doc) => {
                var timestamp = doc.data().dateCreated
                var dateCreated = timestamp.toDate() 
                commit(PUSH_MINDER, {
                    id: doc.id,
                    dateCreated: dateCreated,
                    ...doc.data()
                })

                if(doc.data().intentMeanPatternLabels) { 
                    var imValues =  {
                        'categoryLabelTensor': doc.data().intentMeanPatternLabels.categoryLabelTensor,
                        'nounLabelTensor': doc.data().intentMeanPatternLabels.nounLabelTensor,
                        'nounModLabelTensor': doc.data().intentMeanPatternLabels.nounModLabelTensor,
                        'verbLabelTensor': doc.data().intentMeanPatternLabels.verbLabelTensor,
                        'verbModLabelTensor': doc.data().intentMeanPatternLabels.verbModLabelTensor,
                        'contextCategory': doc.data().intentMeanContextCategory,
                        'specificityLevel': doc.data().intentMeanTaxonomy.intentMeanSpecificityLevel,
                        'parentCategory': doc.data().intentMeanParentCategory
                        //'tagList': doc.data().intentMeanTaxonomy.intentMeanTagList,
                    }
                    dispatch('moderation/setIntentMeanValues', imValues, {root: true})
                }
                if(doc.data().intentMeanTaxonomy) {
                    var moreImValues = {
                        'tagList': doc.data().intentMeanTaxonomy.intentMeanTagList
                    }
                    dispatch('moderation/setIntentMeanTags', moreImValues, {root: true})
                }
                if(doc.data().endPatternLabels) { 
                    var eValues = {
                        'categoryLabelTensor': doc.data().endPatternLabels.categoryLabelTensor,
                        'nounLabelTensor': doc.data().endPatternLabels.nounLabelTensor,
                        'nounModLabelTensor': doc.data().endPatternLabels.nounModLabelTensor,
                        'verbLabelTensor': doc.data().endPatternLabels.verbLabelTensor,
                        'verbModLabelTensor': doc.data().endPatternLabels.verbModLabelTensor,
                        'contextCategory': doc.data().endContextCategory,
                        'specificityLevel': null,
                        'parentCategory': doc.data().endParentCategory
                        //'tagList': doc.data().endTaxonomy.endTagList,
                    }
                    dispatch('moderation/setEndValues', eValues, {root: true})
                }
                if(doc.data().endTaxonomy) {
                    var moreEValues = {
                        'tagList': doc.data().endTaxonomy.endTagList
                    }
                    dispatch('moderation/setEndTags', moreEValues, {root: true})
                }
    
                if(doc.data().valuePatternLabels) { 
                    var vValues = {
                        'categoryLabelTensor': doc.data().valuePatternLabels.categoryLabelTensor,
                        'nounLabelTensor': doc.data().valuePatternLabels.nounLabelTensor,
                        'nounModLabelTensor': doc.data().valuePatternLabels.nounModLabelTensor,
                        'verbLabelTensor': doc.data().valuePatternLabels.verbLabelTensor,
                        'verbModLabelTensor': doc.data().valuePatternLabels.verbModLabelTensor,
                        'contextCategory': doc.data().valueContextCategory,
                        'specificityLevel': null,
                        'parentCategory': doc.data().valueParentCategory
                        //'tagList': doc.data().valueTaxonomy.valueTagList,
                    }
                    dispatch('moderation/setValueValues', vValues, {root: true})
                }
                if(doc.data().valueTaxonomy) {
                    var moreVValues = {
                        'tagList': doc.data().valueTaxonomy.valueTagList
                    }
                    dispatch('moderation/setValueTags', moreVValues, {root: true})
                }
    
                if(doc.data().actionMeanPatternLabels) { 
                    var amValues = {
                        'categoryLabelTensor': doc.data().actionMeanPatternLabels.categoryLabelTensor,
                        'nounLabelTensor': doc.data().actionMeanPatternLabels.nounLabelTensor,
                        'nounModLabelTensor': doc.data().actionMeanPatternLabels.nounModLabelTensor,
                        'verbLabelTensor': doc.data().actionMeanPatternLabels.verbLabelTensor,
                        'verbModLabelTensor': doc.data().actionMeanPatternLabels.verbModLabelTensor,
                        'contextCategory': doc.data().actionMeanContextCategory,
                        'specificityLevel': doc.data().actionMeanTaxonomy.actionMeanSpecificityLevel,
                        'tagList': doc.data().actionMeanTaxonomy.actionMeanTagList,
                        'parentCategory': doc.data().actionMeanParentCategory
                    }
                    dispatch('moderation/setActionMeanValues', amValues, {root: true})
                }
                if(doc.data().actionMeanTaxonomy) {
                    var moreAmValues = {
                        'tagList': doc.data().actionMeanTaxonomy.actionMeanTagList
                    }
                    dispatch('moderation/setActionMeanTags', moreAmValues, {root: true})
                }

                

               


            
            //i++
            })
        })
        commit(SET_PROGRESS_BAR, false)
    },
    setIntentMeanStatement ({commit}, statement) {
        commit(SET_INTENT_MEAN_STATEMENT, {statement: statement})
    },
    setEndStatement ({commit}, statement) {
        commit(SET_END_STATEMENT, {statement: statement})
    },
    setValueStatement ({commit}, statement) {
        commit(SET_VALUE_STATEMENT, {statement: statement})
    },
    setActionMeanStatement ({commit}, statement) {
        commit(SET_ACTION_MEAN_STATEMENT, {statement: statement})
    },

    resetStatements ({commit}) {
        commit(RESET_STATEMENTS)
    },
    setStimulusText ({commit}, data) {
        commit(SET_INTENT_MEAN_STATEMENT_STIMULUS_TEXT, data.m)
        commit(SET_END_STATEMENT_STIMULUS_TEXT, data.e)
        commit(SET_VALUE_STATEMENT_STIMULUS_TEXT, data.v)
        commit(SET_ACTION_MEAN_STATEMENT_STIMULUS_TEXT, data.a)
    },


  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }