<template>
<v-app>
  <form 
    ref="startform" 
    @submit.prevent="submit"
    lazy-validation>
  <v-stepper v-model="e6" vertical non-linear>
    <v-stepper-step 
      :complete="e6 > 1"
      step="1" 
      editable><span id="intentMeanStatementStep">{{ this.intentMeanStatementStepText }}&nbsp;&nbsp; <b>{{this.answer1}}</b></span>
      <!--<small>Think of something you have been meaning to do for awhile</small>-->
    </v-stepper-step>

    <v-stepper-content step="1">
      <v-card color="grey lighten-3" class="mb-2" height="180px">
        <v-layout row wrap>
            <v-flex xs12 class="text-xs-center" mt-4>
                <h2>{{ this.intentMeanStatementStimulusText }}</h2>
            </v-flex>
            <v-flex xs12 sm8 offset-sm2 mt-1 mb-2> 
              <v-text-field
                ref="statement_input1"
                name="answer1"
                :hint="this.intentMeanStatementStimulusHint"
                id="answer1"
                clearable
                :rules="rules"
                counter="60"
                box
                autofocus
                v-on:focus.once="blur"
                @mouseover="giveFocus"
                type="text"
                v-model="answer1"
                required
              ></v-text-field>  
            </v-flex>
        </v-layout>
      
      </v-card>
      <v-layout>
        <v-flex mb-3>
          <v-btn color="primary" @click="continue1">Continue for Next Question</v-btn>
          <v-btn flat to="/">Cancel</v-btn>
        </v-flex>
      </v-layout>
    </v-stepper-content>
    
    <v-stepper-step :complete="e6 > 2" step="2" editable><span id="endStatementStep">{{ this.endStatementStepText }}&nbsp;&nbsp;<b>{{this.answer2}}</b></span></v-stepper-step>

    <v-stepper-content step="2">
      <v-card color="grey lighten-3" class="mb-4" outlined>
        <v-layout row wrap>
            <v-flex xs12 class="text-xs-center" mt-4>
                <h2>{{ this.endStatementStimulusText }}</h2>
            </v-flex>
            <v-flex xs12 sm8 offset-sm2 mt-3 mb-4>
                    <v-layout column>
                        <v-flex>
                            <v-text-field
                                ref="statement_input2"
                                name="answer2"
                                :hint="this.endStatementStimulusHint"
                                box
                                clearable
                                :rules="rules"
                                counter="60"
                                id="answer2"
                                type="text"
                                v-model="answer2"
                                required>
                            </v-text-field>
                        </v-flex>
                    </v-layout>
            </v-flex>
        </v-layout>
      </v-card>
      <v-layout>
        <v-flex mb-3>
          <v-btn color="primary" @click="continue2">Click for Next Question</v-btn>
          <v-btn flat to="/">Cancel</v-btn>
        </v-flex>
      </v-layout>
    </v-stepper-content>

    <v-stepper-step :complete="e6 > 3" step="3" editable><span id="valueStatementStep">{{ this.valueStatementStepText }}&nbsp;&nbsp; <b>{{this.answer3}}</b></span></v-stepper-step>

    <v-stepper-content step="3">
      <v-card color="grey lighten-3" class="mb-2" height="180px">
        <v-layout row wrap>
            <v-flex xs12 class="text-xs-center" mt-4>
                <h2>{{ this.valueStatementStimulusText }}</h2>
            </v-flex>
            <v-flex xs12 sm8 offset-sm2 mt-3 mb-4>
                    <v-layout column>
                        <v-flex>
                            <v-text-field
                                ref="statement_input3"
                                name="answer3"
                                :hint="this.valueStatementStimulusHint"
                                box
                                clearable
                                :rules="rules"
                                counter="60"
                                id="answer3"
                                type="text"
                                v-model="answer3"
                                required
                                >
                            </v-text-field>
                        </v-flex>
                    </v-layout>
                
            </v-flex>
        </v-layout>
      </v-card>
      <v-layout>
        <v-flex mb-3>
          <v-btn color="primary" @click="continue3">Click for Next Question</v-btn>
          <v-btn flat to="/">Cancel</v-btn>
        </v-flex>
      </v-layout>
    </v-stepper-content>

    <v-stepper-step :complete="e6 > 4" step="4" editable><span id="actionMeanStatementStep">{{ this.actionMeanStatementStepText }}&nbsp;&nbsp; <b>{{this.answer4}}</b></span></v-stepper-step>
        <v-stepper-content step="4">
          <v-card color="grey lighten-3" class="mb-4" height="200px">
            <v-layout row wrap>
                <v-flex xs12 class="text-xs-center" mt-4>
                    <h2>{{ this.actionMeanStatementStimulusText }}</h2>
                </v-flex>
                <v-flex xs12 sm8 offset-sm2 mt-3 mb-4>
                        <v-layout column>
                            <v-flex>
                                <v-text-field
                                    ref="statement_input4"
                                    name="answer4"
                                    :hint="this.actionMeanStimulusHint"
                                    box
                                    clearable
                                    :rules="rules"
                                    counter="60"
                                    id="answer4"
                                    type="text"
                                    v-model="answer4"
                                    required>
                                </v-text-field>
                            </v-flex>
                        </v-layout>
                    
                </v-flex>
            </v-layout>
          </v-card>
          <v-layout>
            <v-flex mb-3>
              <v-btn color="primary" type="submit">Submit</v-btn>
            </v-flex>
          </v-layout>
        </v-stepper-content>
     </v-stepper>
    </form>
</v-app>
</template>

<script>
  //import somethingModule from '@/store/something'
  import '@/plugins/vuetify'
  import firebase from '@/firebase'
  import minderModule from '@/store/minder'
  import signInModule from '@/store/signin'
  import userModule from '@/store/user'
  import settingsModule from '@/store/settings'
  import feedbackModule from '@/store/feedback'
  import experienceModule from '@/store/experience'
  import statementModule from '@/store/statement'
  import { mapGetters, mapActions } from 'vuex'

  export default {
    name: "start",
    components: {

    },
    data () {
      return {
        e6: 1,
        valid: true,
        answer1: '',
        answer2: '',
        answer3: '',
        answer4: '',
        rules: [
          (v) => !!v || 'Statement is required',
          (v) => (v && v.length <= 60) || 'Max 60 characters'
        ]
        
      }
    },
    computed: {
      ...mapGetters('minder', {
        intentMeanStatement: 'intentMeanStatement',
        endStatement: 'endStatement',
        valueStatement: 'valueStatement',
        actionMeanStatement: 'actionMeanStatement'
      }),
      ...mapGetters('cms', [
        'intentMeanStatementStimulusText',
        'endStatementStimulusText',
        'valueStatementStimulusText',
        'actionMeanStatementStimulusText',

        'intentMeanStatementStimulusHint',
        'endStatementStimulusHint',
        'valueStatementStimulusHint',
        'actionMeanStatementStimulusHint',

        'intentMeanStatementStepText',
        'endStatementStepText',
        'valueStatementStepText',
        'actionMeanStatementStepText'
      ]),
      ...mapGetters('auth', [
        'loggedIn',
        'profile',
      ])
    },
    methods: {
      ...mapActions('minder', {
        setIntentMeanStatement: 'setIntentMeanStatement',
        resetStatements: 'resetStatements',
        setEndStatement: 'setEndStatement',
        setValueStatement: 'setValueStatement',
        setActionMeanStatement: 'setActionMeanStatement',
        addMinder: 'addMinder',
        createMinder: 'createMinder',
        setStimulusText: 'setStimulusText'
      }),
      ...mapActions('signin', [
        'googleLogin',
        'anonymousLogin',
        'autoSignIn'
      ]),
      ...mapActions('experience', [
        'setXpPhase'
      ]),
      continue1 () {
        if (this.$refs.statement_input1.validate()) {
          this.setIntentMeanStatement(this.answer1)
          this.e6 = 2
          this.$refs.statement_input2.focus()
        }
      },
      continue2 () {
        if (this.$refs.statement_input2.validate()) {
          this.setEndStatement(this.answer2)
          this.e6 = 3
          this.$refs.statement_input3.focus()
        }
      },
      continue3 () {
        if (this.$refs.statement_input3.validate()) {
          this.setValueStatement(this.answer3)
          this.e6 = 4
          this.$refs.statement_input4.focus()
        }
      },
      async submit () {
        
        try {
          
          if (this.$refs.statement_input4.validate()) {
            this.setActionMeanStatement(this.answer4)
            this.e6 = 5

            //check for logged in user
            var unsubscribe = firebase.auth().onAuthStateChanged(async(user) =>{   //Commenting out this code stopped multiple Minders from being created.  I could unsubscribe.
              //var user = firebase.auth().currentUser;  //This line works, but want to see if I can unsubscribe
              unsubscribe()
              if(!user) {
                this.anonymousLogin().then((theUser) => {
                  this.createMinder({source: 'start_page'})
                  this.setXpPhase({'userUid': theUser.uid, 'phase': 'purposeFlowStarted'})
                  this.$router.push('/console')
                })
              } else {
                this.createMinder({source: 'start_page'})
                this.setXpPhase({'userUid': user.uid, 'phase': 'purposeFlowStarted'})
                this.$router.push('/console')
              }
                
            })



            
          }
        } catch (error) {
          alert(error)
        }
      },
      focusThis(stepId) {
        try {
          this.$refs[stepId+'_input'].focus()
        } catch(e) {
          /* eslint-disable no-console */
          console.log('Error', e)
        }
      },
      giveFocus () {
        this.$refs.statement_input1.focus()
      },
      blur () {
        this.$refs.statement_input1.blur()
      },
      
    },
    beforeCreate () {
      if(!this.$store.state.minder) this.$store.registerModule('minder', minderModule)
      if(!this.$store.state.signin) this.$store.registerModule('signin', signInModule)
      if(!this.$store.state.user) this.$store.registerModule('user', userModule)
      if(!this.$store.state.settings) this.$store.registerModule('settings', settingsModule)
      if(!this.$store.state.feedback) this.$store.registerModule('feedback', feedbackModule)
      if(!this.$store.state.experience) this.$store.registerModule('experience', experienceModule)
      if(!this.$store.state.statement) this.$store.registerModule('statement', statementModule)
      
    },
    created () {
      //gtag('config', 'G-H9RD8W4MLR', {'page_path': '/start'});
      this.resetStatements()
      var obj = {
        m: this.intentMeanStatementStimulusText,
        e: this.endStatementStimulusText,
        v: this.valueStatementStimulusText,
        a: this.actionMeanStatementStimulusText
      }
      this.setStimulusText(obj)
      this.autoSignIn()  //If problems occur, comment this out.  I thought this would help
    },
    mounted () {

    },
    updated () {

    },
    watch: {

    }
  }
</script>

<style scoped>
  div.v-stepper__label {
    font-size: 6px;
  }
  div.v-stepper__label small {
    font-size: 6px;
  }
  #meanStatementStep {
    font-size: .9em;
  }
  #endStatementStep {
    font-size: .9em;
  }
  #valueStatementStep {
    font-size: .9em;
  }
@media screen and (min-width: 400px) {
  
  #meanStatementStep {
    font-size: 1em;
  }
  #endStatementStep {
    font-size: 1em;
  }
  #valueStatementStep {
    font-size: 1em;
  }
}
@media screen and (min-width: 700px) {
  
  #meanStatementStep {
    font-size: 1.2em;
  }
  #endStatementStep {
    font-size: 1.2em;
  }
  #valueStatementStep {
    font-size: 1.2em;
  }
}


</style>
