// Setting
export const SET_ALLOW_NOTIFICATIONS = 'SET_ALLOW_NOTIFICATIONS'
export const SET_NICKNAME = 'SET_NICKNAME'
export const SET_PUBLIC_COMMENTS = 'SET_PUBLIC_COMMENTS'
export const SET_SUBSCRIPTION_UPLOADED_TO_USER_FLAG = 'SET_SUBSCRIPTION_UPLOADED_TO_USER_FLAG'
export const SET_NOTIFICATION_SUBSCRIPTIONS = 'SET_NOTIFICATION_SUBSCRIPTIONS'
export const SET_CURRENT_MENU = 'SET_CURRENT_MENU'
export const SET_COOKING_DAYS = 'SET_COOKING_DAYS'


