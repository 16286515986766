import db from '@/db'
//import firebase from '@/firebase'

import {
    SET_XP_STATE,
    RESET_XP_STATE,
    SET_XP_PHASE,
    RESET_XP_PHASE,
    SET_SHOW_JOURNAL_DASHBOARD_VIEW,
    SET_SHOW_JOURNAL_SETUP_VIEW
    /* SET_CURRENT_SESSION_ID,
    RESET_CURRENT_SESSION_ID,
    ADD_TO_XP_SESSION_CLICKSTREAM,
    RESET_XP_SESSION_CLICKSTREAM */
  } from './mutations'

  
  
  const state = {
    newSessionClickstream: [],
    experiencePhase: 'newVisitor',
    minderExperienceStates: [],
    showJournalDashboardView: false,
    showJournalSetupView: false
  }
  
  const getters = {
    newSessionClickstream: ({newSessionClickstream}) => newSessionClickstream,
    experiencePhase: ({experiencePhase}) => experiencePhase,
    minderExperienceStates: ({minderExperienceStates}) => minderExperienceStates,
    showJournalDashboardView: ({showJournalDashboardView}) => showJournalDashboardView,
    showJournalSetupView: ({showJournalSetupView}) => showJournalSetupView
  }
  
  const mutations = {
    [SET_XP_PHASE] (state, payload) {
      state.experiencePhase = payload.phase
    },
    [RESET_XP_PHASE] (state) {
      state.experiencePhase = null
    },
    [SET_XP_STATE] (state, payload) {
      if(state.minderExperienceStates.length < 4) {
        state.minderExperienceStates.push(payload)
      }
    },
    [RESET_XP_STATE] (state) {
      state.experienceState = null
    },
    [SET_SHOW_JOURNAL_DASHBOARD_VIEW] (state, bool) {
      state.showJournalDashboardView = bool
    },
    [SET_SHOW_JOURNAL_SETUP_VIEW] (state, bool) {
      state.showJournalSetupView = bool
    }
    /* [SET_CURRENT_SESSION_ID] (state, payload) {

    },
    [RESET_CURRENT_SESSION_ID] (state) {

    },
    [ADD_TO_XP_SESSION_CLICKSTREAM] (state, payload) {

    },
    [RESET_XP_SESSION_CLICKSTREAM] (state) {

    }, */
  }
  
  const actions = {
    setXpPhase({commit}, payload) {   //This could be renamed to setMinderPhase()
        commit(SET_XP_PHASE, payload)
        if(!payload.vuexOnly) {
          db.collection('users').doc(payload.userUid).set({
            'experiencePhase': payload.phase 
          }, {merge: true})
        }
        
    },
    resetXpPhase({commit}) {
        commit(RESET_XP_PHASE)
    },
    setMinderExperienceState({commit}, payload) {
      commit(SET_XP_STATE, payload)
      db.collection('v1_2020_minders').doc(payload.minderId).set({
        'experienceState': payload.state
      }, {merge: true})
    },
    
    setShowJournalDashboardView ({commit}, bool) {
      commit(SET_SHOW_JOURNAL_DASHBOARD_VIEW, bool)
    },
    setShowJournalSetupView({commit}, bool) {
      commit(SET_SHOW_JOURNAL_SETUP_VIEW, bool)
    }
    
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }