import db from '@/db'

import {
    SET_FEEDBACK,
    RESET_FEEDBACK
  } from './mutations'
  
  const state = {
    newFeedback: {
        feedbackText: '',
        feedbackValue: '',
        feedbackType: '',
        feedbackMeasure: '',

    }
    
  }
  
  const getters = {
    newFeedback: ({newFeedback}) => newFeedback
    
  }
  
  const mutations = {
    [SET_FEEDBACK] (state, payload) {
      state.newFeedback.feedbackText = payload.feedbackText,
      state.newFeedback.feedbackValue = payload.feedbackValue,
      state.newFeedback.feedbackType = payload.feedbackType,
      state.newFeedback.feedbackMeasure = payload.feedbackMeasure
    },
    [RESET_FEEDBACK] (state) {
        state.newFeedback.feedbackText = '',
        state.newFeedback.feedbackValue = '',
        state.newFeedback.feedbackType = '',
        state.newFeedback.feedbackMeasure = ''
    }
  }
  
  const actions = {
    setFeedback({commit}, payload) {
        commit(SET_FEEDBACK, payload)
    },
    resetFeedback({commit}) {
        commit(RESET_FEEDBACK)
    },
    async createFeedbackInFB ({state}, payload) {
        return new Promise((resolve) => {
          (async () => {
            var ref = db.collection('feedback').doc()
            await ref.set({
                ...state.newFeedback,
                feedbackUserUid: payload.userUid,
                feedbackSourceView: payload.feedbackSourceView,
                dateCreated: new Date()
            })
            resolve()
          })()
        })
        
        
        //} else {
            //alert('User not logged in; feeling reading was not created')
        //}
    },
    async saveEmail(_, payload) {
      return new Promise((resolve) => {
        (async () => {
          var emailRef = await db.collection('email').doc()
          await emailRef.set({
            'dateCreated': new Date(),
            'emailAddress': payload,
            'emailId': emailRef.id,
            'isSubscribed': true,
            'sentEmails': {
              'welcomeEmail': false,
            },
          }, {merge: true})
          resolve()
        })()
      })
    },
    async unsubscribeEmail(_, payload) {
      return new Promise((resolve) => {
        (async () => {
          var emailRef = await db.collection('email').doc(payload)
          await emailRef.set({
            'dateUpdated': new Date(),
            'isSubscribed': false,
          }, {merge: true})
          resolve(true)
        })()
      })
    },
    async submitFeedback({rootState, dispatch}, payload) {
      //var sRef = await db.collection('statements').doc()
      var feedbackRef = await db.collection('feedback').doc()
      //var userCheckinRef = await db.collection('users').doc(rootState.auth.profile.uid).collection('checkInLog').doc()
      var newDate = new Date();
      await feedbackRef.set({
        'feeling': payload.feeling,
        'thought': payload.thought,
        'userUid': rootState.auth.profile.uid,
        'dateCreated': newDate
      }, {merge: true})

      
      if(payload.thought.thoughtText !== '') {
        await dispatch('statement/createStatement', {
          'dateCreated': newDate,
          'dateUpdated': newDate,
          'minderId': null,
          'momentId': null,
          'statementFeeling': payload.feeling,  //exclusive property to feelingThought statements
          'statementOriginalText': payload.thought.thoughtText,
          'statementStimulusText': payload.thought.thoughtStimulusQuestion,
          'statementType': 'feedbackOnheedi',
          'statementId': null,
        } , {root: true})
      }



    },

    /* async deleteFeedbackInFB ({state}, payload) {
        const user = firebase.auth().currentUser
        if (user) {
            db.collection('feedback').doc(payload.feelingId).delete()
        } else {
            alert("You are not logged in; feeling was not deleted")
        }
    } */
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }