// Minder
export const SET_NEW_MINDER_ID = 'SET_NEW_MINDER_ID'
export const UPDATE_MINDER = 'UPDATE_MINDER'
export const REMOVE_MINDER = 'REMOVE_MINDER'
export const SET_MINDER = 'SET_MINDER'
export const RESET_MINDER_LIST = 'RESET_MINDER_LIST'
export const PUSH_MINDER = 'PUSH_MINDER'
export const REMOVE_USER_MINDER = 'REMOVE_USER_MINDER'
export const REMOVE_USER_MINDER_ID = 'REMOVE_USER_MINDER_ID'
export const PUSH_USER_MINDER_ID = 'PUSH_USER_MINDER_ID'
export const PUSH_USER_MINDER = 'PUSH_USER_MINDER'
export const SWAP_USER_MINDER = 'SWAP_USER_MINDER'
export const RESET_USER_MINDERS = 'RESET_USER_MINDERS'
export const REVERSE_USER_MINDERS = 'REVERSE_USER_MINDERS'
export const RESET_USER_MINDER_IDS = 'RESET_USER_MINDER_IDS'

export const SET_INTENT_MEAN_STATEMENT = 'SET_INTENT_MEAN_STATEMENT'
export const SET_VALUE_STATEMENT = 'SET_VALUE_STATEMENT'
export const SET_END_STATEMENT = 'SET_END_STATEMENT'
export const SET_ACTION_MEAN_STATEMENT = 'SET_ACTION_MEAN_STATEMENT'


export const SET_INTENT_MEAN_STATEMENT_STIMULUS_TEXT = 'SET_INTENT_MEAN_STATEMENT_STIMULUS_TEXT'
export const SET_VALUE_STATEMENT_STIMULUS_TEXT = 'SET_VALUE_STATEMENT_STIMULUS_TEXT'
export const SET_END_STATEMENT_STIMULUS_TEXT = 'SET_END_STATEMENT_STIMULUS_TEXT'
export const SET_ACTION_MEAN_STATEMENT_STIMULUS_TEXT = 'SET_ACTION_MEAN_STATEMENT_STIMULUS_TEXT'

export const RESET_STATEMENTS = 'RESET_STATEMENTS'
export const SET_LOADING = 'SET_LOADING'
export const SET_GROUP_LOADING = 'SET_GROUP_LOADING'
export const SET_PROGRESS_BAR = 'SET_PROGRESS_BAR'

/*export const SET_INTENT_MEAN_ARRAYS = 'SET_INTENT_MEAN_ARRAYS'
export const SET_END_ARRAYS = 'SET_END_ARRAYS'
export const SET_VALUE_ARRAYS = 'SET_VALUE_ARRAYS'
export const SET_ACTION_MEAN_ARRAYS = 'SET_ACTION_MEAN_ARRAYS'*/

