import db from '@/db'
import firebase from '@/firebase'

import {
  SET_NICKNAME,
  SET_PUBLIC_COMMENTS,
  SET_ALLOW_NOTIFICATIONS,
  SET_SUBSCRIPTION_UPLOADED_TO_USER_FLAG,
  SET_NOTIFICATION_SUBSCRIPTIONS,
  SET_CURRENT_MENU,
  SET_COOKING_DAYS,

  
} from './mutations'

const state = {
  allowNotifications: false,
  subscriptionUploadedToUser: false,
  nickname: null,
  allowPublicComments: true,
  notificationSubscriptions: null,
  currentMenu: 'Menu 1',
  cookingDays: ['Tuesday', 'Thursday', 'Saturday'],

  

}

const getters = {
  allowNotifications: ({allowNotifications}) => allowNotifications,
  currentMenu: ({currentMenu}) => currentMenu,
  cookingDays: ({cookingDays}) => cookingDays,
  subscriptionUploadedToUser: ({subscriptionUploadedToUser}) => subscriptionUploadedToUser,
  nickname: ({nickname}) => nickname,
  allowPublicComments: ({allowPublicComments}) => allowPublicComments,
  notificationSubscriptions: ({notificationSubscriptions}) => notificationSubscriptions,

}

const mutations = {

  [SET_ALLOW_NOTIFICATIONS] (state, data) {
    state.allowNotifications = data.allowNotifications
  },
  [SET_NICKNAME] (state, data) {
    state.nickname = data.nickname
  },
  [SET_PUBLIC_COMMENTS] (state, data) {
    state.allowPublicComments = data.allowPublicComments
  },
  
  [SET_SUBSCRIPTION_UPLOADED_TO_USER_FLAG] (state, data) {
    state.subscriptionUploadedToUser = data
  },

  [SET_NOTIFICATION_SUBSCRIPTIONS] (state, data) {
    state.notificationSubscriptions = data
  },

  [SET_CURRENT_MENU] (state, data) {
    state.currentMenu = data
  },
  [SET_COOKING_DAYS] (state, data) {
    state.cookingDays = data
  }

  

}

const actions = {
  
  async changeNotificationToggleInFS (_,payload) {
    //console.log("payload: ", JSON.stringify(payload))
    await db.collection('users').doc(payload.uid).set({
      notificationSettings: {
        allowNotifications: payload.bool,
        allowNotificationsLastChangedDate: new Date(),
      }
    }, {merge: true})
  },


  async saveSubscription ({commit}, data) {
    var browserInfo = JSON.parse(localStorage.getItem("browserInfo"))
    var subscriptionName = browserInfo.name+"-"+browserInfo.os
    var subscriptionObj = {
      'endpoint': data.endpoint,
      'p256dh_key': data.p256dh_key,
      'auth_key': data.auth_key,
      'isValid': true,
      'dateCreated': new Date() 
    }
    
    // console.log("subscriptionObj", subscriptionObj)
    //If a user is logged in, we update firebase with subscription info
    var unsubscribe = firebase.auth().onAuthStateChanged(async (user) => { 
      unsubscribe()
      if(user) {
        await db.collection('users').doc(user.uid).set({
          'notificationSubscriptions': {
            [subscriptionName]: subscriptionObj
          }
        }, {merge:true})
        commit(SET_SUBSCRIPTION_UPLOADED_TO_USER_FLAG, true)
      }
    })
    //need to also save subscription in localStorage
    localStorage.setItem(subscriptionName, JSON.stringify(subscriptionObj))
    
    

  },

  async setTermsOfUse ({dispatch}) {
    return new Promise(function(resolve, reject) {
      firebase.auth().onAuthStateChanged(async (user) => {  
        if (user) {
          await db.collection('users').doc(user.uid).set({
            'userAcceptedTerms': true,
            'userAcceptedTermsDate': new Date(),
          }, {merge: true})


          await dispatch('auth/setTermsOfUse', true, {root: true})

          resolve()
        }else{
          reject("no user")
        }
      })
    })
  },

  async changeNickname (_, value) {
    return new Promise(function(resolve, reject) {
      firebase.auth().onAuthStateChanged(async (user) => {  
        if (user) {


          await db.collection('users').doc(user.uid).set({
            'nickname': value,
            'nicknameLastChangedDate': new Date(),
          }, {merge: true})


          //await dispatch('auth/setTermsOfUse', true, {root: true})

          resolve()
        }else{
          reject("no user")
        }
      })
    })
  },

  async changePublicCommentSetting (_, value) {
    return new Promise(function(resolve, reject) {
      firebase.auth().onAuthStateChanged(async (user) => {  
        if (user) {


          await db.collection('users').doc(user.uid).set({
            'allowPublicComments': value,
            'allowPublicCommentsLastChangedDate': new Date(),
          }, {merge: true})


          //await dispatch('auth/setTermsOfUse', true, {root: true})

          resolve()
        }else{
          reject("no user")
        }
      })
    })
  },

  async changeMenuCookingSetting (_, value) {
    return new Promise(function(resolve, reject) {
      firebase.auth().onAuthStateChanged(async (user) => {  
        if (user) {
          await db.collection('users').doc(user.uid).set({
            'settings': {
              'cookingSettings': {
                'currentMenu': value,
              }
            }
          }, {merge: true})
          resolve()
        }else{
          reject("no user")
        }
      })
    })
  },
  async changeCookingDaysSetting (_, value) {
    return new Promise(function(resolve, reject) {
      firebase.auth().onAuthStateChanged(async (user) => {  
        if (user) {
          await db.collection('users').doc(user.uid).set({
            'settings': {
              'cookingSettings': {
                'cookingDays': value,
              }
            }
          }, {merge: true})
          resolve()
        }else{
          reject("no user")
        }
      })
    })
  },

  async saveSubscriptionToUser ({commit, state}, data) {

    if(!state.subscriptionUploadedToUser) {
      //grab the subscription object from local storage
      var browserInfo = JSON.parse(localStorage.getItem("browserInfo"))
      var subscriptionName = browserInfo.name+"-"+browserInfo.os
      var subscriptionObj = JSON.parse(localStorage.getItem(subscriptionName))

      await db.collection('users').doc(data.uid).set({
        'notificationSettings': {
          'notificationToggle': true,
        },
        'notificationSubscriptions': {
          [subscriptionName]: subscriptionObj
        }
      }, {merge:true})

      commit(SET_SUBSCRIPTION_UPLOADED_TO_USER_FLAG, true)

    }
    
  },

  async setUserSettings ({commit}, payload) {
    if(payload.data) {
      commit(SET_ALLOW_NOTIFICATIONS, {allowNotifications: payload.data.notificationSettings.allowNotifications})
      commit(SET_NICKNAME, {nickname: payload.data.nickname})
      commit(SET_PUBLIC_COMMENTS, {allowPublicComments: payload.data.allowPublicComments})
      commit(SET_NOTIFICATION_SUBSCRIPTIONS, payload.data.notificationSubscriptions)
      commit(SET_CURRENT_MENU, payload.data.settings.cookingSettings.currentMenu)
      commit(SET_COOKING_DAYS, payload.data.settings.cookingSettings.cookingDays)
    }
    
  },
  async refreshNotifications ({dispatch, rootState}) {
    return new Promise(function(resolve) {
      (async () => {

        if(Notification.permission !== 'granted'){
          const permission = await dispatch('askPermission');
          if(permission === 'granted') {
            const subscriptionResult = await dispatch('subscribeUserToPush')
            var subscription = JSON.parse(JSON.stringify(subscriptionResult))
            var data = {
              'endpoint': subscription.endpoint,
              'p256dh_key': subscription.keys.p256dh,
              'auth_key': subscription.keys.auth,
              'uid': rootState.auth.profile.uid
            }
            // Update taxonomyStatus
            dispatch('saveSubscription',data)
          
          }
          //resolve()
        }else{
          const subscriptionResult2 = await dispatch('subscribeUserToPush')
          var subscription2 = JSON.parse(JSON.stringify(subscriptionResult2))
          var data2 = {
            'endpoint': subscription2.endpoint,
            'p256dh_key': subscription2.keys.p256dh,
            'auth_key': subscription2.keys.auth,
            'uid': rootState.auth.profile.uid
          }
          // Update taxonomyStatus
          dispatch('saveSubscription',data2)

        }

        resolve()
      })()
    })
  },

  async setupNotifications ({dispatch, rootState}) {
    return new Promise(function(resolve) {
      (async () => {
        if((Notification.permission !== 'granted') || (Notification.permission === 'denied')){
          const permission = await dispatch('askPermission');
          if(permission === 'granted') {
            const subscriptionResult = await dispatch('subscribeUserToPush')
            var subscription = JSON.parse(JSON.stringify(subscriptionResult))
            var data = {
              'endpoint': subscription.endpoint,
              'p256dh_key': subscription.keys.p256dh,
              'auth_key': subscription.keys.auth,
              'uid': rootState.auth.profile.uid
            }
            // Update taxonomyStatus
            dispatch('saveSubscription',data)
          
          }
          //resolve()
        }
        if(rootState.auth.loggedIn){
          dispatch('changeNotificationToggleInFS', { 'bool': true, 'uid': rootState.auth.profile.uid })
        }
        resolve()
      })()
    })
  },

  async changeNotification ({dispatch, rootState},value) {
    //console.log("settings/changeNotification called");
    //console.log("value: ", value)
    if(value) {
      if(!("Notification" in window)) {
        /* eslint-disable no-console */
        console.log("This browser does not support web notifications")
      }
      //console.log("Notification.permission", Notification.permission)
      if((Notification.permission != 'granted') || (Notification.permission === 'denied')){

        try {
          const permission = await dispatch('askPermission');
          if(permission === 'granted') {
            const subscriptionResult = await dispatch('subscribeUserToPush')
            if(subscriptionResult) {
              //console.log('subscriptionResult:'+subscriptionResult)
              var subscription = JSON.parse(JSON.stringify(subscriptionResult))
              
              //console.log("subscription endpoint: "+ subscription.endpoint)
              var data = {
                endpoint: subscription.endpoint,
                p256dh_key: subscription.keys.p256dh,
                auth_key: subscription.keys.auth,
                uid: rootState.auth.profile.uid
              }
              // Update taxonomyStatus
              dispatch('saveSubscription',data)
            }
            
          }
        } catch(e) {
          /* eslint-disable no-console */
          console.log("Error: ", e)
        }
        
      }
    }
    if(rootState.auth.loggedIn){        
      dispatch('changeNotificationToggleInFS', { 'bool': value, 'uid': rootState.auth.profile.uid })
    }
  },

  async askPermission () {
    return new Promise(function(resolve) {
      (async () => {
        const permissionResult = await Notification.requestPermission()
        resolve(permissionResult)
      })()
    })
  },
  
  async subscribeUserToPush () {
    return new Promise((resolve) => {
      (async () => {
        if(navigator.serviceWorker) {
          const registration = await navigator.serviceWorker.register(`${process.env.BASE_URL}heedi-service-worker.js`)
          //console.log("got registration object")
          const vapidPublicKey = 'BNUNAtoOc08UVhpraYrc_rabfr-fVzzdJkwinlDGcett-PL2DhlnvAqWpGDGv2lFTI1O6b9ZsBCm42cS97-XjzI';
          const convertedVapidKey = await urlBase64ToUint8Array(vapidPublicKey);
          const subscribeOptions = {
            'userVisibleOnly': true,
            'applicationServerKey': convertedVapidKey
          };
          //console.log("register subscription with pushManager")
          const subscription = await registration.pushManager.subscribe(subscribeOptions)
          resolve(subscription)
        }else{
          resolve(null)
        }
      })()
    })
    function urlBase64ToUint8Array(base64String) {
      return new Promise(function(resolve) {
        const padding = '='.repeat((4 - base64String.length % 4) % 4);
        const base64 = (base64String + padding)
            .replace(/-/g, '+')   //removed a '\' before the dash because console showed an error, if this don't work now
            .replace(/_/g, '/');
        const rawData = window.atob(base64);
        const outputArray = new Uint8Array(rawData.length);
        for (var i = 0; i < rawData.length; ++i) {
            outputArray[i] = rawData.charCodeAt(i);
        }
        resolve(outputArray);
      })
    }
    /* return navigator.serviceWorker.register(`${process.env.BASE_URL}heedi-service-worker.js`)
      .then(registration => {
        console.log("got registration object")
        const vapidPublicKey = 'BJ4ipkTeoUY686uBGAVks98z3RRYiwC4rBxcack0vx609xoUI5munYKML29VCDULAavq3g9PiopvNOYnAej9MLI';
        const convertedVapidKey = this.urlBase64ToUint8Array(vapidPublicKey);
        const subscribeOptions = {
          userVisibleOnly: true,
          applicationServerKey: convertedVapidKey
        };
        console.log("getting ready to register")
        return registration.pushManager.subscribe(subscribeOptions);
      })
      .then(function(pushSubscription) {
        console.log("subscriberUserToPush complete")
        return pushSubscription;
      }); */
  },
  


  
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}